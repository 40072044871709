export default {
  grasslandTitle: "Sustainable Grassland Management Project",
  
  sustainableTitle: "Why is grassland management so important?",

  sustainableContent1: "Grasslands provide a wide variety of ecosystem services, such as regulating water supply, controlling erosion, and providing natural spaces for pollinating insects. However, when it comes to properly managing grasslands, one of the most important benefits is mitigating the effects of climate change by increasing soil organic carbon (SOC). The ability of grasslands to store carbon comes mainly from their well-developed root systems, which possess an extremely strong soil-binding capability. When viewed globally, grasslands can store around 343 billion tonnes of carbon in the top one meter of soil and have a potential soil sequentration rate of 0.5 tonnes carbon per hectare per year. Given the size and widespread distribution of grasslands, the role that they play in the terrestial cabon cycle is of crucial importance. ",
  sustainableContent2: "However, due to the increasing pressures placed on grasslands from human activities such as crop farming and animal agriculture, many natural grasslands have become degraded and exhibit a reduction in SOC content.[1]",
  sustainableContent3: "At QYH, the sustainable grassland management projects that we develop are designed to reverse the degradation of grasslands from overgrazing and the pressures of climate change, maintaining the ecosystem's long-term health. Through the project's activities, the grassland is able to recover and enhance its ability to naturally sequester atmospheric carbon within the soil. [2] According to verified international methodologies, a project will adopt the following sustainable management practices:",
  
  sustainableContentli1: "improve the rotation of livestock between grassland areas;",
  sustainableContentli2: "limit the number of livestock grazing on degraded grassland areas;",
  sustainableContentli3: "restore severely degraded grassland areas by replanting with native grass species;",
  sustainableContentli4: "ensure appropriate long-term grassland management via cooperation with local communities. ",

  sustainablePicOrigin: "Photo: Early stages of grassland restoration at the Gansu Guazhou Sustainable Grassland Mangement Project (VCS 4218), showing the re-fencing of degraded grassland and fire detection tower.",
  grasslandCarbonTitle: "Sustainable Grassland Management: carbon removal or carbon reduction？",

  grasslandCarbonContent1: "Generally speaking, a carbon removal project is one that removes atmospheric carbon and stores it elsewhere, such as in living systems (like grasslands or forests) or underground in rock formations (as with carbon capture and storage). Conversely, a carbon reduction project is one that reduces emissions from entering the atmosphere, such as switching to solar or wind power (instead of burning coal) or using clean cookstoves (instead of burning wood for fuel). ",
  grasslandCarbonContent2: "Given the variety of project activities that are involved in a Sustainable Grassland Management Project, this type of project both removes and reduces CO2 emissions. However, when looking at the data verified by third-parties, the total emission reductions of QYH's Sustainable Grassland Management Projects are 98% carbon removal and 2% carbon reduction. Essentially what this means is that an overwhelming majority of the project's emission reductions come from removing existing carbon from the atmosphere, as opposed to simply reducing the amount of carbon emitted into the atmosphere.",
  grasslandCarbonContent3: "As stated above, the power of the grasslands to remove so much carbon from the atmosphere comes from their robust root systems and ability to fix carbon within the soil. Thus, a Sustainable Grassland Management Project has a much higher carbon removal contribution when compared to its carbon reduction contribution. But as the project consists of numerous types of emission reduction activities, carbon reductions nevertheless occur. Besides increasing soil organic carbon, the project also:",
  
  carbonContentli1: "reduces the use of chemical fertilizers",
  carbonContentli2: "reduces the burning of biomass",
  carbonContentli3: "reduces manure deposition and accumulation",
  carbonContentli4: "reduces emissions from animal respiration/enteric fermentation",
  carbonContentli5: "reduces fossil-fuel use in farming equipment",

  carbonContent4: "In short, a Sustainable Grassland Management Project is not just a removal or a reduction project, but a holistic approach that enables ecosystem regeneration while allowing for sustainable agricultural activity.",
  carbonLegend1Title: "Carbon Removal",
  carbonLegend1Content1: "Increases in soil organic carbon (SOC) stocks",
  carbonLegend1Content2: "Grassland restoration via grazing management and re-seeding",
  carbonLegend2Title: "Carbon Reduction",
  carbonLegend2Content1: "Non-CO2 GHG emissions reduction",
  carbonLegend2Content2: "Limiting grazing days and grazing areas",
  carbonLegend2Content3: "*No reduction in grazing herd sizes",


  verificationTitle: "How are grassland projects verified?",
  verificationContent: "To ensure that a sustainable grassland management project is additional and that its credits have high integrity and quality, a multi-step process is required to design and implement the project with local stakeholders, validate and verify its activities with third-parties, and finally, issue the credits that can be claimed by end-users as part of net-zero climate goals. This process is outlined below and simplified for clarity:",
  verificationItem1: "Site Selection: conduct a comprehensive project feasibility study, selecting for areas that meet the requirements of the international methodolgies (grassland ecosystems that have historically suffered from various degrees of degradation and require additional, financial support).",
  verificationItem2: "Project Development: design a locally-tailored grassland restoration plan, along with local stakeholder consultation, prior to project implementation.",
  verificationItem3: "Restoration and Management: implementation of grassland restoration and sustainable management practices, e.g., grass seeding, fence building, rest and rotational grazing, local engagement & employment",
  verificationItem4: "Validation and Verification: QYH collaborates with third-parties to complete the project validation and verification procedures according to international standards. (For our Sustainable Grassland Management projects, QYH adheres to VCS, the most widely-used international carbon standard.) ",
  verificationItem5: "Credit Issuance and Sale: after the project is verified, QYH will issue carbon credits from the project, which buyers can use to meet their climate goals. To ensure the long-term sustainable management of the project (including all of its environmental, social, and economic benefits) financial support is crucial. ",
  verificationItem6: "Long-term Monitoring: integrity is a key component of a high-quality carbon project. Throughout a project's lifetime, QYH is committed to maintaining data accuracy and transparency. Project monitoring is continously conducted in partnership with local stakeholders to guarantee future credit issuances and on-going positive impact of the project.",

  contributionTitle: "Benefits for Local Communities",

  carouselTitle1: "Improve livelihoods",
  carouselContent1: "locals will have access to sustainable sources of income and increased job opportunities for the duration of the project.",
  carouselTitle2: "Climate impact",
  carouselContent2: "strengthen carbon storage capacity of grasslands, making natural systems and local communities more resilient to the effects of climate change. ",
  carouselTitle3: "Enhance biodiversity",
  carouselContent3: "expand and preserve the habitats of local flora and fauna, allowing biodiversity to flourish and create positive ecological feedback loops.",

  acceptanceTitle: "QYH is dedicated to creating high-quality carbon offset projects and is always commited to your climate pledge. Explore more about QYH's grassland projects here.",
  acceptanceSubTitle: "Explore QYH’s Grassland Projects",
  acceptanceBUtton: "Learn More",
}

























