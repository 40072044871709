export default {
  guazhouTitle: "Gansu Guazhou Sustainable Grassland Management Project",

  guazhouExplainContent: "Prior to project implementation, the majority of the grassland within the project area had experienced moderate degradation resulting from decades of overgrazing, loss of high-quality foraging plants, and a limited capacity for natural renewal and regeneration. Through the grassland restoration efforts in this project, biodiversity and land quality are expected to show continuous improvement over the years. This will create a more favorable habitat for animals and contribute to the enhanced accumulation of soil organic carbon.",

  guazhouExplainData1Title: "300,000 tCO2e",
  guazhouExplainData1Content: "emissions stored in the soil each year",
  guazhouExplainData2Title: "140,000 hectares",
  guazhouExplainData2Content: "land protected",
  guazhouExplainData3Title: "27",
  guazhouExplainData3Content: "protected animals protected",
  guazhouExplainData4Title: "450",
  guazhouExplainData4Content: "jobs created",

  guazhouExplainDetail1: "Project ID：VCS 4218",
  guazhouExplainDetail2: "Location：Gansu, China",
  guazhouExplainDetail3: "Methodology：VM0026",
  guazhouExplainDetail4: "Project Start Date： June 1st, 2020",
  guazhouExplainDetail5: "",

  measureTitle: "Grassland Restoration Methods",
  measureProject1Title: "Grass seeding (4,197.16 ha)",
  measureProject1Explain: "Four native grass species were selected to be planted or seeded in the area. At the end of the seeding period, the grassland was fenced off and grazing was strictly prohibited in the first year.",
  measureProject2Title: "Rotational grazing (88,481.90 ha)",
  measureProject2Explain: "Based on a specified grazing order, grazing cycle and zoned grazing time (reduced from 150 days per year to 125 days), the grassland is grazed by area and used in turn. This allows the grassland time to recover from grazing.",
  measureProject3Title: "Rest grazing (48,098.69 ha)",
  measureProject3Explain: "Fences are built in dedicated rest grazing areas. Each year during the growing season (from May 1 to August 31), grazing is prohibited. ",
  measureProject4Title: "Other",
  measureProject4Explain: "Grassland guardians are trained to patrol on the grassland regularly to identify potential risks like rodents and fire. In addition to this, several Eagle’s nests were built in the area. This not only increases biodiversity by providing extra habitats for native species, but also reduces impact on the environment by avoiding the use of chemical products.",
  measureStautsOpen: "Open",
  measureStautsClose: "Close",

  grassSeedsTitle: "Seeding Selection",
  grassSeedsExplain1: "Seeding selection is based on the soil and climate conditions in the project area. The annual average evapotranspiration in Guazhou County is greater than annual average precipitation. Due to the impact of climate change and overgrazing, the soil salinization is relatively serious. The soil texture in the project areas includes loam, sandy loam and sandy.",
  grassSeedsExplain11: "",
  grassSeedsExplain12: "",
  grassSeedsExplain2: "Four native species (crested wheat grass, sheep’s fescue, alkali grass and milky Iris) were selected based on their ability to adapt to the severe local environment. Even in cases of severe frost, these grass species are resilient and will grow in the following spring. These native species are also proven to be more suitable for providing food and habitat for native animals.",
  grassSeedsExplain21: "",
  grassSeedsExplain22: "",
  grassSeedsProject1Title: "milky iris",
  grassSeedsProject1Title1: "Iris lacteal",
  grassSeedsProject1Explain: "milky iris has a well-developed root system and possesses extremely strong soil-binding capabilities, making it beneficial for soil erosion control. In dry and infertile land conditions, the above-ground growth of the stems and leaves may decrease by more than 20%, but the root growth increases by over 10%, with roots reaching depths of more than 1 meter. Milky iris is highly resilient, with tolerance to high temperatures, drought, waterlogging, and salinization conditions. Additionally, milky iris exhibits remarkable resistance to diseases, pests, and rodents, even when interplanted with other plants. ",
  grassSeedsProject2Title: "Crested wheat grass",
  grassSeedsProject2Title1: "Agropyron cristatum",
  grassSeedsProject2Explain: "Crested wheat grass has well-developed roots with a protective rhizosheath covering the outer root layer. Its narrow and curled leaves close their stomata during periods of drought. Even during severe drought, the leaves can remain green, and the plant can quickly resume growth when exposed to rainwater. Therefore, crested wheat grass exhibits strong resistance to drought and cold, and has sand-fixing capabilities, making it one of the most drought-resistant grasses in the Poaceae family found in China. This plant can safely overwinter in temperatures as low as -40°C, is heat-tolerant, and does not have stringent soil requirements.",
  grassSeedsProject3Title: "Sheep’s fescue ",
  grassSeedsProject3Title1: "Festuca ovina",
  grassSeedsProject3Explain: "Sheep's fescue has a wide ecological tolerance—its great ability to adapt to poor soils is due to mycorrhizal fungi, which increase the absorption of water and nutrients and are potential determinants of plant community structure. The grass is resilient to cold, drought, and moderately saline soils, and has growth that varies with the ecological environment. In dry or highly saline conditions, sheep’s fescue tends to grow in a low and stunted manner. In contrast, when it grows in areas with better moisture conditions and lower salinity, it can form thick clumps and rapidly reproduce thanks to its vigorous rhizomes.",
  grassSeedsProject3ExplainOrigin: "Source: PPBC https://ppbc.iplant.cn/tu/994834",
  grassSeedsProject4Title: "Alkali grass",
  grassSeedsProject4Title1: "Puccinellia distans",
  grassSeedsProject4Explain: "Alkali grass has a broad range and can be found on plains, in mountainous river valley grasslands, salt-affected low-altitude grasslands, and near streams. Known as a highly resilient grass species, it can thrive on moderately to severely saline soils.",

  influenceTitle: "Impacts",
  influenceSubTitle1: "Biodiversity",
  influenceExplain: "In Guazhou County there are 27 species of nationally protected animals. This includes 7 animal species under first-class national protection and 20 under second-class national protection, along with 22 species of wild animals protected by CITES (Convention on International Trade in Endangered Species) and 8 species under the protection of the IUCN Red List (International Union for Conservation of Nature), covering EN, VU, NT animals. By improving the quality and availability of grasslands and providing suitable food and habitat conditions, native animals have better opportunities for survival and reproduction.",
  influenceSubTitle2: "Focal Species",
  influenceProject1Title: "Gobi Argali",
  influenceProject1Title1: "Ovis darwini",
  influenceProject1SubTitle1: "China Biodiversity Red List: CR",
  influenceProject1SubTitle2: "IUCN Red List: NT",
  influenceProject1Explain: "Gobi argali is a wild sheep living on the highlands of Mongolia and Northwest China. Males have two large corkscrew shaped horns, some measuring 190 cm (6 ft 3 in) in total length and weighing up to 23 kg (51 lb). Hunters often kill Gobi argali to collect their beautiful horns, contributing to their endangered status along with factors like overgrazing and grassland degradation. Due to ecological restoration providing a favorable habitat, the presence of Gobi argali was once again observed in Guazhou County in 2023.",
  influenceProject2Title: "Goitered Gazelle ",
  influenceProject2Title1: "Gazella subgutturosa",
  influenceProject2SubTitle1: "Protection Class in China: II",
  influenceProject2SubTitle2: "IUCN Red List: VU",
  influenceProject2Explain: "The goitered gazelle, commonly known as “black-tailed gazelle“ by locals, can be found in both the southern and northern parts of Guazhou County. They inhabit sands, gravel plains and limestone plateaus. In the 1900’s, goitered gazelles were abundant, found in almost every desert or semi-desert area throughout the Middle East and Central Asia. In 2001, their entire population was estimated at 120,000 to 140,000. Major threats include unrestrained poaching and habitat destruction. Habitat destruction is primarily due to economic and agricultural development.",
  influenceProject3Title: "Snow Leopard",
  influenceProject3Title1: "Panthera uncia",
  influenceProject3SubTitle1: "Protection Class in China: I",
  influenceProject3SubTitle2: "IUCN Red List: VU",
  influenceProject3Explain: "Traces of snow leopard activities are occasionally observed in the high mountains over 3000 meters in the southern part of Guazhou County. The snow leopard, an iconic big cat native to the mountain ranges of Central and South Asia, is listed as vulnerable on the IUCN Red List. The global population is estimated to be fewer than 10,000 mature individuals, and it is expected to decline by about 10% by 2040. It is mainly threatened by poaching and habitat destruction following infrastructural developments.",


  residentTitle: "Community",
  residentExplain1: "Without the Gansu Guazhou Sustainable Grassland Management Project, the grassland will continue to degrade, posing a significant threat to the local ecosystem and those with livelihoods that depend on it, such as traditional herders. To avoid the continued loss of grasslands, sustainable measures, such as rotational grazing and rest grazing, are necessary. Due to the project’s activities, the long-term benefits brought to the local communities are numerous, including sustainable sources of income, increased job opportunities, and higher ecological and aesthetic values from a restored environment. ",
  residentExplain2: "During the project implementation period, residents were offered short-term jobs such as grass seeding and fence building, as well as long-term jobs like employment as grassland guardians. All the employees that were involved in grass seeding and fence building were paid 180 RMB (25.86 USD) per day. Grassland guardians are paid around 2,000 RMB (287.36 USD) per year*.",
  residentExplain21: "",
  residentExplain3: "*Most of the employed residents are from ethnic minorities. Approximately 450 local herders, including 150 women, participated in grass seeding and fence building. Among them, 130 local herders, including 45 women, were employed as grassland guardians. Of the 450 job opportunities, 130 are permanent positions, and 320 are temporary jobs that last throughout the entire project crediting period.",
  residentExplain31: "",
  residentExplain32: "",
  downloadTitle: "Project Documents",
  downloadProject1: "Project Description ",
  downloadProject2: "Validation Report",
  downloadProject3: "Monitoring Report",
  downloadProject4: "Verification Report",
}









