import Vue from 'vue'
// import ElementUI from 'element-ui';
import routes from "./router/router";
// import routerMobile from "./router/router-mobile";
import 'babel-polyfill';
// 按需引入组件
import element from './element/index'
Vue.use(element)
// 引入公共的css
import 'element-ui/lib/theme-chalk/index.css';
import i18n from '@/lang';
// import ElementLocale from 'element-ui/lib/locale'

import './assets/css/reset.css';
import './assets/css/layout.css';
import App from './App.vue'
import debounce from "lodash.debounce";
Vue.config.productionTip = false;
/* swiper */
import 'swiper/dist/css/swiper.min.css';
/* 头部组件 */
import header from './components/header/header.vue' // eslint-disable-line no-unused-vars
import headerMobile from './components/header/header-mobile.vue' // eslint-disable-line no-unused-vars
/* 底部组件 */
import footer from './components/footer/footer.vue' // eslint-disable-line no-unused-vars
import footerMobile from './components/footer/footer-mobile.vue' // eslint-disable-line no-unused-vars
let router = null; // eslint-disable-line no-unused-vars
const windowResize = function() { // eslint-disable-line no-unused-vars
	/*取到屏幕的宽度*/
	var width = window.innerWidth; // eslint-disable-line no-unused-vars
  if(width < 1170) {
    var html = document.getElementsByTagName('html')[0];
    // router = routerMobile;
    router = routes;
    Vue.component(headerMobile.name,headerMobile);
    Vue.component(footerMobile.name,footerMobile);
    var fontSize = 100 / 750 * width;
		/*设置fontsize*/
		html.style.fontSize = fontSize + 'px';
    window.onresize = function() {
      var html = document.getElementsByTagName('html')[0];
      /*取到屏幕的宽度*/
      var width = window.innerWidth;
      /* 640 100  320 50 */
      var fontSize = 100 / 750 * width;
      /*设置fontsize*/
      html.style.fontSize = fontSize + 'px';
    }
    if( localStorage.getItem("windowWidth") > 1170 ) {
      location.reload()
    }
  }else {
    router = routes;
    Vue.component(header.name,header);
    Vue.component(footer.name,footer)
    if( localStorage.getItem("windowWidth") < 1170 ) {
      location.reload()
    }
  }
  localStorage.setItem("windowWidth",width)
}
windowResize();
window.onresize = debounce(windowResize, 100);

// Vue.use(ElementUI, {
//   i18n: (key, value) => i18n.t(key, value)
// })
Vue.prototype.i18n = i18n;
// ElementLocale.i18n((key, value) => i18n.t(key, value))
new Vue({
  render: h => h(App),
  router,
  i18n,
}).$mount('#app')
