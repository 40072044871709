export default {
  ahref: "http://xiaoxi.qyh-investment.com/",
  back:'返回',
  back1:'返回',
  whoWeAre: "公司介绍",
  whoWeAreContent1: "北京千予汇环保科技有限公司（千予汇）成立于2014年，2016年起活跃于国际碳市场。千予汇的主要业务包括碳减排项目的投资、碳减排项目的认证咨询、碳汇项目销售及相关咨询。同时，我们也为企业提供专业的碳中和服务，帮助企业实现碳中和目标。",
  whoWeAreContent2: "千予汇在中国参与了多个林业碳汇项目的投资。我们持续在国内发展更多的碳汇项目，帮助改善环境、实现可持续发展。并且希望引进更多的国际合作，通过全球碳交易市场机制，来推广基于自然的气候行动方案，吸引更多的人加入我们，共同为缓解气候变化努力。",
  timeline: "发展历程",
  timeline1: "· 北京千予汇环保科技有限公司成立",
  timelineyear1: "2014",
  timeline2: "· 合作开发VCS标准的森林碳汇项目",
  timelineyear2: "2016",
  timeline3: "· 在北美、欧洲、东亚建立碳汇销售网络",
  timelineyear3: "2017-2019",
  timeline4: " · 在河南方城和唐河投资开发造林项目",
  timeline41: " · 加入IETA（国际碳排放交易协会）",
  timeline42:" · 河南方城和唐河投资开发造林项目加入IETA(国际碳排放交易协会)",
  timelineyear4: "2020",
  timeline5: "· 启动中国各省市多项林业碳汇开发",
  timeline51: "",
  timeline52:"· 与国内外多所高校就双碳领域达成校企合作成为中国头部碳汇企业",
  timelineyear5: "2021",
  timeline6: "· 由伟高达创投投资，千予汇完成Pre-A轮融资",
  timeline61: "· 启动瓜州县草原碳汇项目",
  timeline62:"· 启动肃北县草原碳汇项目",
  timelineyear6: "2022",
  team: "我们的团队",
  teamItem1: "郝轩正",
  teamItemPost1: "董事长 ",
  teamItem2: "林宇阳",
  teamItemPost2: "CEO",
  teamItemPost21:"CEO",
  teamItem3: "Nicholas Manthey ",
  teamItem31: "",
  teamItem32: "",
  teamItemPost3: "国际商务总监",
  teamItemPost3Mobile: "国际商务总监",
  teamItem4: "宋贝贝",
  teamItemPost4: "市场总监",
  teamItem5: "陈晨",
  teamItemPost5: "项目总监",
  teamItem6: "郝银萍",
  teamItemPost6: "董事长特助",
  teamItem7: "朴俊源",
  teamItemPost7: "UI设计师",
  teamItemGet1:"中国节能协会理事",
  teamItemDetail1:"通过多年商务与金融的经验， Carl了解到真正的财富是美好的家乡与环境，于是2014年成立了千予汇环保来实现他的理想，期望以碳交易的路径减少全球温室气体排放。他是中国林业碳汇的投资者，也是全球暖化议题的宣讲者，并积极参与国内外政府间的气候会议，宣讲气候行动的急迫性。在《巴黎协议》实施后，结合科技创新与节能减排是他的愿景，他相信只要越来越多人共同担负起气候责任，一定可以完成可持续性发展目标。",
  teamItemGet2:"淡江⼤学企管硕⼠ (碳交易专业)",
  teamItemDetail2:"Neo拥有10多年的国际商务经验和碳交易市场的专业知识，他致力于推广碳交易制度和减排项目的发展，做为一个碳市场倡导者，曾受世界银行、亚洲开发银行和中国人民大学等组织邀请演讲，并且积极的参与联合国气候大会等相关的气候科技组织活动，他希望借由国际交易来实现全球减排量的流通，来提高全球气候治理的效率，同时解决发展中国家与发达国家的技术落差，完成共同但有区别的气候责任。",
  teamItemGet3:"南京大学—约翰斯·霍普⾦斯⼤学中美⽂化研究中⼼",
  teamItemDetail3:"Nick 负责千予汇商务推广，与国内外客户建立战略合作伙伴关系。Nick之前的学术研究集中在区块链环保领域的研究，特别是如何促进碳汇市场的发展，运⽤区块链技术达到环保的⽬的，并在China-US Focus 上发表了《区块链与⽓候：新能源前沿科技的发展》。来华⼗年，深耕中国市场，精通汉语和跨⽂化交流。",
  teamItemGet4:"浙江外国语学院 管理学学士",
  teamItemDetail4:"Beibei 负责与全球合作伙伴的沟通、谈判与合作；千予汇在中国的林业碳项目投资；管理千予汇投资的项目组合和项目开发。Beibei在碳汇交易行业有三年的经验，对森林保护、缓解碳排放以及支持当地发展方面经验丰富。在加入千予汇之前，Beibei曾在中国陆军服役两年。",
  teamItemGet5:"英国⻉德福德⼤学 ⼯商管理学士",
  teamItemDetail5:"陈晨主要负责碳汇项目对接，并负责千予汇在国内的宣传推广以及与合作伙伴关系维护。 加入千予汇环保之前，陈晨曾在国内外多家企业担任运营经理，具有丰富的商务合作经验。",
}







