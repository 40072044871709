export default {
  ahref: "http://news.qyh-investment.com",
  back:'Back to Staff',
  back1:'Back',
  whoWeAre: "Who We Are",
  whoWeAreContent1: "Beijing Qianyuhui Environmental Technology Co., Ltd. (QYH) was established in 2014 and has been active in the international carbon market since 2016. QYH's core business services include project investment, carbon offset verification consultation, and carbon offset sales. At the same time, we also provide professional consultation services for our clients to help them achieve their carbon neutral goals.",
  whoWeAreContent2: "QYH has invested in a number of forestry carbon projects in China. We continue to develop more projects at home which aim to help improve the environment and achieve sustainable development of local communities. We at QYH are anticipating continued growth in the voluntary offset market and hope that through market-based mechanisms and nature-based solutions, more people will join us to mitigate the effects of climate change. ",
  timeline: "Timeline",
  timeline1: "· Beijing Qianyuhui Environmental Technology Co., Ltd.",
  timelineyear1: "2014",
  timeline2: "· Successfully co-developed 3 VCS  forestry projects in Jiangxi and Inner Mongolia.",
  timelineyear2: "2016",
  timeline3: "· Established a carbon credit sales network of over 20 companies based in North America, Europe and East Asia.",
  timelineyear3: "2017-2019",
  timeline4: "· Independently-developed the Henan Fangcheng and Tanghe Afforestation Project. ",
  timeline41: "· Became members of the International Emissions Trading Association (IETA).",
  timeline42:"Independently-developed the Henan Fangcheng and Tanghe Afforestation Project.Became members of the International Emissions Trading Association (IETA).",
  timelineyear4: "2020",
  timeline5: "· Initiated the development of multiple ",
  timeline51: "forestry carbon projects across China.",
  timeline52:"· Established partnerships with top universities in China to achieve carbon neutrality. Recognized as a leading developer in China's VCM",
  timelineyear5: "2021",
  timeline6: "· Completed Pre-A Funding Round with Vickers Venture Partners",
  timeline61: "· Commenced development of the Guazhou Sustainable Grassland Management Project",
  timeline62:"· Commenced development of the Subei Sustainable Grassland Management Project Recognized as a leading developer in China's VCM",
  timelineyear6: "2022",
  team: "Team",
  teamItem1: "Carl Hao",
  teamItemPost1: "Chairman",
  teamItem2: "Neo Yu-yang Lin",
  teamItemPost2: "CEO",
  teamItemPost21:"Co-founder & CEO",
  teamItem3: "Nicholas Manthey",
  teamItem31: "",
  teamItem32: "",
  teamItemPost3: "Head of Business Development",
  teamItemPost3Mobile: "Head of Development",
  teamItem4: "Beibei Song",
  teamItemPost4: "Marketing Director",
  teamItem5: "Ryan Chen",
  teamItemPost5: "Project Director",
  teamItem6: "Cici Hao",
  teamItemPost6: "Chairman Assistant",
  teamItem7: "Junyuan Piao",
  teamItemPost7: "User Interface Designer",
  teamItemGet1:`Director of China Energy Conservation Association`,
  teamItemDetail1:`Through years of experience in business and investment, Carl has learned that preserving nature and local communities are our lasting wealth. To achieve these goals, he founded Qianyuhui Investment in 2014, hoping to reduce global greenhouse gas emissions through carbon trading. Carl is an investor in China's forestry carbon projects, an advocate for climate action, and an active participant in domestic and international climate conferences.`,
  teamItemGet2:`Master of Business Administration, Tamkang University 
  (Concentration on Emission Trading Systems)`,
  teamItemDetail2:`Neo has 10 years of experience in international business and is an expert in global carbon markets. He is committed to promoting the establishment of carbon trading systems and the development of emission reduction projects. As a carbon market expert, Neo has been invited to speak at events hosted by the World Bank, Asian Development Bank and Renmin University of China. In addition, he has actively participated at COP24, COP25 and other climate tech events. Neo hopes to reduce emissions through international carbon trading, improve the efficiency of global climate governance, as well as resolve the technological gap between developing and developed countries.`,
  teamItemGet3:`Master of International Studies: Energy, Resources, and 
  Environment, Johns Hopkins SAIS-Nanjing University`,
  teamItemDetail3:`Nicholas is responsible for developing growth opportunities at QYH Investment and building strategic partnerships, both domestic and international. He has focused his prior academic research on blockchain applications designed for carbon trading, especially within the forestry market segment. His publications include “Blockchain and Climate: A New Energy Frontier” (China-US Focus, 2018) and “The Impact of Blockchain Technology on the Voluntary Forest Carbon Offset Market" (Hopkins-Nanjing MA Thesis, 2019). Nicholas is professionally proficient in Mandarin Chinese and has been based in mainland China since 2010. `,
  teamItemGet4:`B.Sc. in Business Administration, Zhejiang International Studies University`,
  teamItemDetail4:`Beibei Song is in charge of communication, negotiation and cooperation with global partners to support QYH Investment’s VCS forestry carbon projects in China and manages QYH Investment’s project portfolio and project development. Beibei Song has three years’ experience in the emissions trading industry and has a strong passion for protecting forests and reducing carbon emissions for their partners, as well as supporting local communities. Prior to joining QYH Investment, Beibei Song served in the special forces of the Chinese military for two years. `,
  teamItemGet5:`Bachelor's Degree in Business Administration
  University of Bedfordshire (UK)`,
  teamItemDetail5:"Ryan is mainly responsible for the communication with local government and communities,  branding of Qianyuhui in China and the maintenance of its partnership with our clients. Before joining Qianyuhui Environmental Protection, Ryan worked as the BD in China and UK. He has rich experience in business cooperation.",
} 