export default {
  carbonTitle: "Carbon Project Development",

  carbonSubTitle1: "How QYH Assures Project Quality",
  carbonQualityTitle1: "No Double-counting ",
  carbonQualityContent1: "Each carbon credit registry operates independently, creating a scenario where double-counting theoretically might occur. QYH assures that a project registerred with one of the international registries (such as VCS or Gold Standard) will not be simultaneously registered with CCER (China Certified Emission Reduction) and confirms that credits from our projects will not be double-claimed for climate action.",
  carbonQualityTitle2: "Clear Land Ownership Rights",
  carbonQualityContent2: "The structure of land ownership is complicated and varies from region to region. Prior to developing a carbon offset project, QYH obtains clear evidence of land ownership and land use rights from local communties and governments, ensuring that each project legally accounts for all stakeholders. Subsequently, all stakeholders within the project areas are duly notified of the project development and activities.",
  carbonQualityTitle3: "Clear Project Boundaries",
  carbonQualityContent3: "Some nature-based projects (such as grassland and afforestation) cover very large areas of land, often in remote or rural locations. Creating clear boundaries for each project helps to improve the accuracy of emission reduction calculations, ultimately strengthening the climate impact of the project. For example, areas that include roads and rivers must be excluded from a grassland project, as they do not contribute towards emission reductions.",
  carbonQualityTitle4: "Protection of Residents' Rights",
  carbonQualityContent4: "QYH places significant emphasis on ensuring that project implementation does not negatively impact the livelihoods of local residents. This is achieved through continuous project monitoring, stakeholder consultation, and dialogue with local communities and governments.",
  carbonQualityTitle5: "Integrity of Project Documents",
  carbonQualityContent5: "Quantification, monitoring, reporting, and verification are four steps to ensure the quality of carbon projects. Over the course of the project's lifetime, data collection, site visits, and third-party assessment are embeded in the above steps to maintain transparency and accuracy. ",
  carbonQualityTitle6: "Additionality",
  carbonQualityContent6: "Evaluating a project's additionality can be a long and diffcult process, but QYH rigiously follows the requirements of international methodologies and utilizes remote sensing, physical site visits, local records, local dialogue, and professional literature to detemine additionality for each project.",
  carbonQualityContent61: "These tools allow QYH to establish credible baseline emission scenarios, which are used to calculate the climate impact of each project, and help to avoid overestimating emission reductions and preventing over-crediting.",

  carbonSubTitle2: "Standards of Projects",
  carbonStandardContent1: "The Verified Carbon Standard (VCS) is a joint initiative of the Climate Group (CG), the International Emissions Trading Association (IETA) and the World Economic Forum (WEF) established in 2005. VCS projects are managed by independent non-profit associations and require voluntary carbon reductions to be real, measurable, permanent, independently verifiable and unique. VCS is a relatively mature carbon standard and the most widely used standard in the world. VCS project types are basically the same as CDM project types, but VCS has broad support from the emission reduction industry.",
  carbonStandardContent2: "The Climate, Community & Biodiversity (CCB) certification was proposed by the Climate, Community and Biodiversity Alliance (CCBA) in 2003. It aims to improve the quality and co-benefits of carbon reduction projects. In recent years, this label has been increasingly sought out by emissions reductions projects in the voluntary carbon market in order to enhance climate and social impact.",
  carbonStandardContent3: `CCER (China Certified Emission Reduction) refers to the quantification and verification of greenhouse gas emission reductions for specific projects in China and are registered in the national voluntary greenhouse gas emission reduction trading registration system. CCER, which was launched in March 2012 and suspended in March 2017, was officially restarted on October 20th, 2023, with the Ministry of Ecology and Environment's publication of "Management Measures for Voluntary Emission Reduction Trading of Greenhouse Gases (Implementation)".`,


  carbonSubTitle3: "Type of Projects",
  carbonContent1: `QYH specializes in the development of forestry and grassland carbon offset projects, collectively known as "nature-based solutions" (or NBS for short).NBS projects focus on removing`,
  carbonContent2: "",
  carbonContent3: "carbon dioxide from the atmosphere by sequestering it into the biosphere (such as in trees and the soil) through activities such as afforestation, forestry management, and grassland restoration.",
  carbonBoxContent: "Co-benefits: By storing CO2 in natural systems, NBS projects not only help to restore nature and protect biodiversity, but also increase the economic value of naturasystems by generating carbon credits. These carbon credits, in turn, can help support local communities while also providing an incentive to sustainably manage local natural resources.",
  carbonProjectItemTitle1: "Afforestation",
  carbonProjectItemContent1: `Afforestation refers to tree-planting activities in areas that are considered  "barren land" —without forest coverage for at least 10 years. For these projects, baseline scenarios are established and additional carbon sequestration in forest growth issued to calculate the amount of carbon credits that can be issued per year.`,
  carbonProjectItemTitle2: "Grassland",
  carbonProjectItemContent2: "Through SGM, grasslands are restored by re-seeding and re-fencing the project area, and sustainable grazing practices are put in place for local herders. ",
  carbonProjectItemContent21: "Learn More",
  carbonProjectItemTitle3: "IFM",
  carbonProjectItemContent3: "IFM projects are aimed at existing forests. Through IFM, standing forests are allowed to grow beyond their baseline scenarios, promoting forest growth and increasing carbon sink potential.",
}

















